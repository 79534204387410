import "../../static/css/noscript.css";

import * as Cookies from "Utils/cookies";

import Logo from "Components/Logo/Logo";
import React from "react";
import { graphql } from "gatsby";
import { navigate } from "gatsby";
import styled from "styled-components";

const Content = styled.div`
  width: 100vw;
  height: 100vh;
`;

const IndexPage = (props) => {
  const languagePaths = props.data.allSitePage.edges.map((a) => {
    return a.node.path;
  });

  let isocode = "en";

  if (typeof window !== "undefined") {
    /** https://developer.mozilla.org/en-US/docs/Web/API/Navigator/language */
    let language = navigator.language.toLowerCase();

    const languageShort = language.substr(0, 2);
    // Check if we support full language `en-GB` `es-PL`
    if (languagePaths.includes(`/${language}/`)) {
      isocode = language;
      // Else check if we support short language `en` `es`
    } else if (languagePaths.includes(`/${languageShort}/`)) {
      isocode = languageShort;
    } else {
      switch (language) {
        case "zh":
          isocode = "zh-cn";
          break;
        case "pt":
          isocode = "pt";
          break;
        case "es":
          isocode = "es";
          break;
        default:
          isocode = "en";
          break;
      }
    }

    Cookies.set("isocode", isocode);
    navigate(`/${isocode}/`);
  }

  return (
    <Content>
      <div className="noscript">
        <div className="noscript__content">
          <Logo />
        </div>
      </div>
    </Content>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query queryPages {
    allSitePage {
      edges {
        node {
          path
        }
      }
    }
  }
`;
